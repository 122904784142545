import {$getModuleColor, $getModuleIcon} from "./appModules";

type SidebarMenuItem = {
    title: string;
    path: string;
    icon?: string;
    color?: string;
    initial?: string;
    ability?: string[];
    children?: SidebarMenuItem[];
}

type SidebarMenuSection = {
    title: string;
    ability?: string[];
    menu: SidebarMenuItem[];
}

type SidebarMenu = SidebarMenuSection[];

export const sidebarMenu: SidebarMenu = [
    {
        title: 'default',
        menu: [
            {
                title: 'menu.dashboard',
                path: 'service',
                icon: $getModuleIcon('dashboard'),
                color: $getModuleColor('dashboard'),
            },
            {
                title: 'menu.configurator',
                path: 'service-configurator',
                icon: $getModuleIcon('configurator'),
                color: $getModuleColor('configurator'),
                ability: ['viewAny', 'configurations'],
                children: [
                    {
                        title: 'menu.componentsTree',
                        path: 'service-configurator-components',
                        ability: ['viewAny', 'components']
                    },
                    {
                        title: 'menu.variantsTree',
                        path: 'service-configurator-variants',
                        ability: ['viewAny', 'variants']
                    },
                ]
            },
            {
                title: 'menu.orders',
                path: 'service-orders',
                icon: $getModuleIcon('orders'),
                color: $getModuleColor('orders'),
                ability: ['viewAny', 'orders']
            },
            {
                title: 'menu.settings',
                path: 'service-settings',
                icon: $getModuleIcon('settings'),
                color: $getModuleColor('settings'),
                ability: ['viewAny', 'settings'],
                children: [
                    {
                        title: 'menu.users',
                        path: 'service-users',
                        ability: ['viewAny', 'users']
                    },
                    {
                        title: 'menu.loginProviders',
                        path: 'service-settings-loginProviders',
                        ability: ['viewAny', 'loginProviders']
                    },
                ]
            },
        ]
    },
    // {
    //     title: 'Abschnitt 2',
    //     menu: [
    //         {
    //             title: 'Element 1',
    //             initial: 'E1',
    //             path: '/',
    //
    //         },
    //         {
    //             title: 'Element 2',
    //             path: '/',
    //             initial: 'E2',
    //         },
    //     ]
    // }
];